import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const { STATUSES: { NEED_REACTION } } = DataConstants;

const ReasonInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    validate = true,
    value = "",
    status,
    originalReason,
    localReasons: propReasons = [],
    disabled,
    compact,
    readOnly,
    placeholder = originalReason
      ? uiTexts.addMoreAccurateReason
      : uiTexts.describeThisTransaction,
    onChange,
    onFocus,
    onBlur,
    ...restProps
  } = props;

  const focusedRef = useRef(false);

  const dispatch = useDispatch();

  const [reasons, setReasons] = useState([]);

  const userRole = useSelector(getUserRole);

  const [opened, setOpened] = useState(false);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const hasValue = !!(value && value.trim());

  const reasonsList = useMemo(() => {
    const localStateRasons = propReasons.filter((item) => value !== item);

    if (!hasValue || value.length < Constants.SEARCH_TEXT_MIN_LENGTH) return localStateRasons;

    const globalReasons = reasons.map((item) => item.reason);

    return [...new Set([...globalReasons, ...localStateRasons])];
  }, [hasValue, propReasons, value, reasons]);

  const fetchReasonsDebounced = useMemo(() => {
    return Utils.debounce(async(newValue) => {
      if (opened && newValue && newValue.length >= Constants.SEARCH_TEXT_MIN_LENGTH) {
        const result = await dispatch(TransactionsActions.fetchTransactionsReasonsList([newValue]));

        if (focusedRef.current && Array.isArray(result)) setReasons(result);
      }
    }, Constants.TEXT_FIELD_DEBOUNCE_TIMEOUT);
  }, [opened, dispatch]);

  const handleChange = useCallback((newValue, event) => {
    onChange(newValue, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((newValue, event) => {
    onChange(newValue, event);
  }, [onChange]);

  const handleFocus = useCallback((event) => {
    if (onFocus) onFocus(event);
    focusedRef.current = true;
  }, [onFocus]);

  const handleBlur = useCallback((event) => {
    if (onBlur) onBlur(event);
    focusedRef.current = false;
  }, [onBlur]);

  useEffect(() => {
    fetchReasonsDebounced(value);
  }, [fetchReasonsDebounced, value]);

  return (
    <div className={classNames(Css.reasonInput, compact && Css.compact)}>
      <AutoCompleteInput
        {...restProps}
        useCaret={!!reasonsList.length}
        disabled={disabled}
        opened={opened}
        readOnly={readOnly}
        value={value}
        valid={validate && !disabled && hasValue}
        invalid={validate && !disabled && !hasValue}
        iconBeforeTitle={uiTexts.reason}
        placeholder={!value && disabled ? uiTexts.noReason : placeholder}
        iconBefore={disabled ? Icons.NotePencil : undefined}
        items={reasonsList}
        dropDownLabel={uiTexts.lastReasons}
        setOpened={setOpened}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onAutoComplete={handleAutoComplete} />
      {(businessUser && status === NEED_REACTION && originalReason)
        ? (
          <AutoTooltip className={Css.originalReason}>
            <Icons.Warning />
            <span className={Css.label}>{uiTexts.oldReason}:</span>
            <span>{originalReason}</span>
          </AutoTooltip>
        )
        : null}
    </div>
  );
};

export default React.memo(ReasonInput);
