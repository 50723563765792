import { compareTwoStrings } from "string-similarity";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import Utils from "./Utils";

const { STATUSES: { EXPORTED, TO_REPORT } } = DataConstants;

const searchContact = (contacts, id, name, usesItems) => {
  let contactData = null;

  const foundItems = [];

  const nameLowerCased = name && name.trim().toLocaleLowerCase();

  contacts.some((contact) => {
    if (contact.id === id) {
      contactData = contact;

      return true;
    } else if (!usesItems || contact.subType !== DataConstants.CONTACT_SUB_TYPES.VENDOR) {
      if (nameLowerCased) {
        const contactNameLowerCased = contact.name && contact.name.trim().toLocaleLowerCase();

        if (nameLowerCased === contactNameLowerCased) {
          foundItems.push([1, contact]);
        } else {
          const similarity = compareTwoStrings(nameLowerCased, contactNameLowerCased);

          if (similarity > Constants.DATA_SIMILARITY_COEFFICIENT) {
            foundItems.push([similarity, contact]);
          }
        }
      }
    }

    return false;
  });

  return contactData || (
    foundItems.length && foundItems.sort(([similarityA], [similarityB]) => similarityB - similarityA)[0][1]
  ) || null;
};

export default class Transactions {
  static getTransactionState(transactionData, businessUser) {
    const { address = {}, category = {} } = transactionData;

    const transactionState = {
      address,
      category,
      id: transactionData.id,
      vendorId: transactionData.vendorId,
      class: transactionData.class,
      item: transactionData.item,
      location: transactionData.location,
      taxRate: transactionData.taxRate,
      project: transactionData.project,
      tags: transactionData.tags,
      reason: (businessUser && transactionData.newReason) || "",
      status: transactionData.status,
      usesItems: transactionData.usesItems,
      directCategorySelection: !!category.code
    };

    return transactionState;
  }

  static getTransactionAdvancedType({ type, credit, extraData }) {
    const {
      ADVANCED_TRANSACTION_TYPES: {
        BANK_FEED,
        PURCHASE,
        EXPENSE,
        INCOME,
        SPENT,
        RECEIVED,
        RECEIVED_PAYMENT,
        PAYMENT,
        DEPOSIT,
        CREDIT_CARD_CREDIT
      }
    } = DataConstants;

    const { TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

    if (extraData) {
      if (extraData.integrationService === IntegrationServices.XERO.value) {
        if (extraData.xeroBankTransactionId) return type === WITHDRAW ? SPENT : RECEIVED;

        return BANK_FEED;
      } else if (extraData.integrationService === IntegrationServices.ZOHO.value) return type === WITHDRAW ? EXPENSE : DEPOSIT;
    }

    let advancedType = extraData
      && Utils.uncapitalizeText((extraData.quickBooksTransactionType || extraData.quickBooksDesktopTransactionType));

    if (advancedType === PURCHASE) advancedType = EXPENSE;
    else if (advancedType === PAYMENT) advancedType = RECEIVED_PAYMENT;

    return advancedType
      ? (advancedType === EXPENSE && credit ? CREDIT_CARD_CREDIT : advancedType)
      : (type === WITHDRAW ? EXPENSE : INCOME);
  }

  static getChangedFields(state, original) {
    const changedFields = [
      state.address?.name !== original.address?.name && ["address", state.address],
      state.vendorId !== original.vendorId && ["vendorId", state.vendorId],
      state.category?.code !== original.category?.code && ["category", state.category],
      state.class?.id !== original.class?.id && ["class", state.class],
      state.project?.id !== original.project?.id && ["project", state.project],
      state.location?.id !== original.location?.id && ["location", state.location],
      state.taxRate?.id !== original.taxRate?.id && ["taxRate", state.taxRate],
      state.item?.id !== original.item?.id && ["item", state.item]
    ].filter(Boolean);

    return changedFields.length ? Object.fromEntries(changedFields) : null;
  }

  static readyToReview(transaction) {
    const { STATUSES: { NEED_REACTION } } = DataConstants;

    const { status, documentId, reason, category = {} } = transaction || {};

    return status === NEED_REACTION && (documentId || (reason && reason.trim()) || category.code);
  }

  static checkIsReadyToReview(itemState, transaction, advancedDocumentsWorkflow) {
    const { NEED_REACTION } = DataConstants.STATUSES;

    const { reason, category = {} } = itemState || {};

    const { status, documentId, reason: originalReason } = transaction || {};

    return status === NEED_REACTION && (
      (documentId && advancedDocumentsWorkflow)
      || (reason && reason.trim())
      || (category.code && !originalReason)
    );
  }

  static validateToApprove(itemState = {}, transaction = {}, businessUser, allowApproveWithoutPayee) {
    const { type, extraData } = transaction || {};

    const advancedType = type && extraData && Transactions.getTransactionAdvancedType({ type, extraData });

    const transferTransaction = advancedType === DataConstants.ADVANCED_TRANSACTION_TYPES.TRANSFER;

    const {
      vendorId,
      category = {},
      item = {},
      location = {},
      project = {},
      taxRate = {},
      class: classValue = {}
    } = itemState;

    return !businessUser
        && (vendorId || transferTransaction || allowApproveWithoutPayee)
        && (category.code || item.id)
        && (!location.name || location.id)
        && (!project.name || project.id)
        && (!taxRate.name || taxRate.id)
        && (!classValue.name || classValue.id);
  }

  static checkIsReadyToApprove(
    itemState = {},
    transaction = {},
    businessUser = false,
    advancedDocumentsWorkflow = false,
    allowApproveWithoutPayee = false
  ) {
    const { status } = itemState;

    return status !== TO_REPORT
        && status !== EXPORTED
        && (!advancedDocumentsWorkflow || !transaction.documentId)
        && Transactions.validateToApprove(itemState, transaction, businessUser, allowApproveWithoutPayee);
  }

  static hasChanges(state, original) {
    return [
      state.address?.name !== original.address?.name,
      state.category?.code !== original.category?.code,
      state.class?.id !== original.class?.id,
      state.project?.id !== original.project?.id,
      state.location?.id !== original.location?.id,
      state.taxRate?.id !== original.taxRate?.id,
      state.item?.id !== original.item?.id
    ].some(Boolean);
  }

  static updateContactData(transactionState, contactsData, integrationService) {
    const { vendorId, address, status, usesItems } = transactionState;

    if (
      (!vendorId && !address.name)
      || (status === EXPORTED && integrationService !== IntegrationServices.QUICK_BOOKS.value)
    ) return transactionState;

    const foundContact = searchContact(contactsData, vendorId, address.name, usesItems);

    if (!foundContact) return transactionState;

    return {
      ...transactionState,
      vendorId: foundContact.id,
      address: {
        ...address,
        name: foundContact.name
      }
    };
  }
}
