import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessData } from "selectors/businesses";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import TransactionsActions from "actions/TransactionsActions";

const {
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL },
  TRANSACTION_TYPES: { WITHDRAW },
  STATUSES: { TO_REVIEW }
} = DataConstants;

const useAttachDocument = ({ id: transactionId, documentId, type, vendorId, status }) => {
  const dispatch = useDispatch();

  const {
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const toReviewStatus = status === TO_REVIEW;

  return useCallback(async(file) => {
    try {
      if (advancedDocumentsWorkflow && vendorId && !toReviewStatus && !businessUser) {
        await dispatch(TransactionsActions.editTransaction(transactionId, { vendorId }));
      }
      if (documentId) await dispatch(BusinessesActions.unpairMatches(transactionId, documentId, false, true));

      const result = await dispatch(DocumentsActions.uploadDocuments([file],
        { transactionAttachment: !advancedDocumentsWorkflow, paymentType: type === WITHDRAW ? BUY : SELL }));

      if (result) {
        const [newDocument] = result;

        if (newDocument) {
          await dispatch(BusinessesActions.pairMatches(transactionId, newDocument.id, false, true));

          return newDocument;
        }
      }
    } catch (exception) {}

    return false;
  }, [advancedDocumentsWorkflow, businessUser, dispatch, documentId, toReviewStatus, transactionId, type, vendorId]);
};

export default useAttachDocument;
